module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-90},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["UA-103158323-1"]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Trogman Avocats","short_name":"Trogman Avocats","start_url":"/","background_color":"#663399","display":"minimal-ui","icon":"static/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"2fe06230b3c5cfedcd52e87743e5bb85"},
    },{
      plugin: require('../node_modules/@atido/gatsby-theme-showcase/gatsby-browser.js'),
      options: {"plugins":[],"projectPath":"expertises","googleAnalyticsID":"UA-103158323-1","anchorOffset":-90,"siteTitleForManifest":"Trogman Avocats","contentfulConfig":{"spaceId":"0yg0cdnnha7u","accessToken":"e3lCNW3NZMUpQ4ZYLSmCCmtNlsWTuvAf8kkPGd-cQVg"}},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
